html,
body {
  padding: 0;
  margin: 0;
  font-family: Helvetica, sans-serif, Baskerville, Georgia, Times, "Times New Roman", serif;
  font-size: 14px;
  background-color: #000;
  color: #efe0c1;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:focus {
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

.home-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 70px 0 50px 0;
  margin: 0;
}

.nodule-of-flint {
  padding: 5px;
  margin-top: 10px;
}

.nodule-image {
  width: 350px;
  height: 350px;
  background-color: black;
  color: transparent;
}

@media screen and (min-width: 600px) {
  .nodule-image {
    width: 250px;
    height: 250px;
  }
}

.layout-container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.main {
  padding: 1rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav {
  width: 100vw;
  margin: 20px 20px 20px 20px;
  height: 40px;
  padding: 0px;
  color: #efe0c1;
  display: flex;
  position: fixed;
  top: 0;
}

.nav ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  list-style: none;
}

.nav-right {
  float: right;
  text-align: right;
}

.about {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-container {
  text-align: center;
  width: 70vw;
}

.about-website {
  margin-top: 50px;
  color: rgb(180, 180, 180);
}

.arrow {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 60px;
  height: auto;
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .about-container {
    width: 40vw;
  }
}
